import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import imgADHD from "../../assets/image/akkadian/services-adhd.png";
import imgGeneral from "../../assets/image/akkadian/services-general.png";
import imgAutism from "../../assets/image/akkadian/services-autism.png";
import imgAllied from "../../assets/image/akkadian/services-allied.svg";

import imgDot from "../../assets/image/akkadian/dot-shape2.png";

const Services  = ({ id, subtitle }) => {
  
  return (
    <>
      {/* <!--Service section  -->*/}
      <div id={id} className="service-section bg-default-4 pt-12 pb-5 py-lg-15">
        <div>
          <Row className="justify-content-center">
            <Col md="9" lg="7" xl="6">
              <div className="section-title text-center mb-11 mb-lg-19 px-lg-3">
{/*                 <h4 className="pre-title gr-text-12 text-red text-uppercase mb-7">
                  Our services
                </h4> */}
                <h2 className="title gr-text-4 px-6">
                  {subtitle}
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center position-relative gr-z-index-1">
            <Col className="mb-md-6 mb-sm-5 mb-3"
              xs="10" sm="10" md="6" lg="4"
              // style={{minWidth: "280px"}}
              data-aos="fade-up"
              data-aos-duration="750"
            >
              <Link to="/services/adhd-testing" className="service-card rounded-10 gr-hover-shadow-4 d-flex flex-column text-center pt-15 px-9 pb-11 dark-mode-texts bg-light-orange h-100">
                <div className="card-img mb-11">
                  <img src={imgADHD} width="140" height="auto"  alt="..." />
                </div>
                <h3 className="card-title gr-text-6 mb-6 text-dark">Adult ADHD Testing</h3>
                <p className="gr-text-9 mb-11 text-dark">
                  Fast and accurate <strong>ADHD Testing, Assessments and Diagnosis</strong> for adults - with comprehensive treatment plans.
                </p>
                <p className="gr-text-9 btn-link with-icon text-dark mt-auto">
                  Learn more{" "}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </p>
              </Link>

            </Col>
			
            <Col className="mb-md-6 mb-sm-5 mb-3"
              xs="10" sm="10" md="6" lg="4"
              // style={{minWidth: "280px"}}
              data-aos="fade-right"
              data-aos-duration="750"
            >
              <Link to="/services/general-psychiatry"  className="service-card rounded-10 gr-hover-shadow-4 d-flex flex-column text-center pt-15 px-9 pb-11 dark-mode-texts bg-light-green h-100">
                <div className="card-img mb-11">
                  <img src={imgGeneral} width="140" height="auto" alt="..." />
                </div>
                <h3 className="card-title gr-text-6 mb-6 text-dark">General Psychiatry</h3>
                <p className="gr-text-9 mb-11 text-dark">
                  Assessments and reviews for conditions such as PTSD, OCD, Anxiety, Depression, and Personality disorders.
                </p>
                <p className="gr-text-9 btn-link with-icon text-dark mt-auto">
                  Learn more{" "}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </p>
              </Link>
            </Col>

{/*             <Col className="mb-md-6 mb-sm-5 mb-3"
              xs="10" sm="10" md="6" lg="3"
              // style={{minWidth: "280px"}}
              data-aos="fade-left"
              data-aos-duration="750"
            >
              <Link to="/services/autism-spectrum-disorder"  className="service-card rounded-10 gr-hover-shadow-4 d-flex flex-column text-center pt-15 px-9 pb-11 dark-mode-texts bg-light-blue h-100">
                <div className="card-img mb-11">
                  <img src={imgAutism} width="140" height="auto"  alt="..." />
                </div>
                <h3 className="card-title gr-text-6 mb-6 text-dark">Adult Autism Assessments</h3>
                <p className="gr-text-9 mb-11 text-dark">
                  Full <strong>Autism Spectrum Disorder (ASD) Assessments</strong> for adults - conducted by our multi-disciplinary team. 
                </p>
                <p className="gr-text-9 btn-link with-icon text-dark mt-auto">
                  Learn more{" "}
                  <i className="icon icon-tail-right text-dark font-weight-bold"></i>
                </p>
              </Link>
            </Col> */}
			
            <Col className="mb-md-6 mb-sm-5 mb-3"
              xs="10" sm="10" md="6" lg="4"
              // style={{minWidth: "280px"}}
              data-aos="fade-left"
              data-aos-duration="750"
            >
              <Link to="/services/allied-health"  className="service-card rounded-10 gr-hover-shadow-4 d-flex flex-column text-center pt-15 px-9 pb-11 dark-mode-texts bg-akk-blue h-100">
                <div className="card-img mb-11">
                  <img src={imgAllied} width="140" height="auto"  alt="..." />
                </div>
                <h3 className="card-title gr-text-6 mb-6 text-white">ADHD Coaching & Psychology</h3>
                <p className="gr-text-9 mb-11 text-white">
                  Our <strong>accredited ADHD Coaches and Registered Psychologists</strong> have all your health care neeeds covered.
                </p>
                <p className="gr-text-9 btn-link with-icon text-white mt-auto">
                  Learn more{" "}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </p>
              </Link>
              <div
                className="gr-abs-br-custom gr-z-index-n1"
                data-aos="zoom-in-right"
                data-aos-duration="750"
              >
                <img src={imgDot} alt="" />
              </div>
            </Col>

          </Row>
        </div>
      </div>
    </>
  );
};

export default Services;
