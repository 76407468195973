import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import Divider from '../sections/akkadian/Divider';
import docInfo from "../assets/docs/Patient_Info.pdf";
import { Helmet } from "react-helmet";
import { Link } from 'gatsby';
import { LiveChatWidget, EventHandlerPayload } from '@livechat/widget-react'
import Services from "../sections/akkadian/Services";

// ADD FOR LIVEWIDGET INTEGRATION after <PageWrapper>  ("yarn add @livechat/widget-react" ... adds package.json dependency)
//
//    <LiveChatWidget
//      license="14142372"
//      visibility="maximized"
//    />	

const AboutPage = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          buttonText: "Contact us"
        }}
        footerConfig={{
          theme: "light",
          page: "akkadian-home",
          style: "style2", //style1, style2
        }}
      >

	
        <Helmet>
          <title>Akkadian Health - ADHD Testing & Assessments | Telehealth Australia</title>
          <meta
            name="description"
            content="Comprehensive mental health services. Affordable ADHD Assessments and Testing in Australia via telehealth."
          />
        </Helmet>

        <div className="inner-banner">
          <Container>
            <Row className="justify-content-center mt-md-6 pt-24 pt-lg-29">
              <Col lg="9" xl="8">
                <div className="px-md-12 text-center mb-1 mb-lg-3">
                  <h1 className="title gr-text-3 mb-9 mb-lg-12">Services for patients</h1>
                  <h3 className="gr-text-6 mb-6">
                    We are committed to providing the highest quality and most efficient care.
                  </h3>
                  <p className="gr-text-9 mb-6">
                    At Akkadian Health we believe that high quality mental healthcare should be
                    equally available to everyone – anywhere and anytime. We've built a team of dedicated mental
                    health clinicians, psychologists and RANZCP-registered psychiatrists in Australia.
                  </p>
                  <p className="gr-text-9 mb-6">
                    Speak to your GP to arrange a referral to our services.
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center mt-md-3 pt-3 pt-lg-6">
              <Services id="services" />
            </Row>

          </Container>
        </div>
		

        <Divider />

        <div className="px-12 text-center py-7 py-lg-10">
          <p className="gr-text-8 mb-1">
            Please review our <Link to="/faq" className="gr-text-9">FAQ </Link>
            for more detailed information.
          </p>
        </div>

        <div className="px-12 text-center mb-8 mb-lg-10">
          <Link to="/faq">
            <Button variant="warning" className="gr-hover-y w-80 w-sm-auto">
              FAQ
            </Button>
          </Link>
        </div>

      </PageWrapper>
    </>
  );
};
export default AboutPage;

