import React from 'react';
import styled from 'styled-components';
import { Container } from "react-bootstrap";

const DividerElement = styled.div`
    background-color: #979797;
    height: 1px;
    opacity: .26;
`

const Divider = () => (
    <Container>
        <DividerElement />
    </Container>
)

export default Divider;